<template>
  <div id="main-survey">
    <signableContractCreator
      v-if="
        this.getCurrentAuditToSign.modeOfIntervention == 'F2F' ||
        this.getCurrentAuditToSign.modeOfIntervention == ''
      "
      hidden
    />
    <signableContractCreatorVirtual
      v-if="this.getCurrentAuditToSign.modeOfIntervention == 'Virtual'"
      hidden
    />
    <!-- <EmployabilitySurveyCreator v-if="surveyType == 'Employability'" hidden/>
        <SkillsSurveyCreator v-if="surveyType == 'Skills'" hidden/>
        <DigitalSkillsSurveyCreator v-if="surveyType == 'DigitalSkills'" hidden/>
        <LifeSkillsSurveyCreator v-if="surveyType == 'LifeSkills'" hidden/> -->
    <div id="divToPrint">
      <div
        id="main-contract"
        style="background-color: White; padding: 1.5rem; color: black"
      >
        <div
          style="
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            padding: 0.5rem 0;
            width: 100%;
            font-size: 1.1rem;
            display: flex;
            justify-content: center;
            text-align: center;
          "
        >
          <b v-if ="getCurrentAuditToSign.reason == 'Exit Plan'">Exit Plan</b>
          <b v-else>Employment Action Plan</b>
        </div>
        <p style="font-size: 0.8rem">
          The following plan demonstrates a commitment between
          {{ getCustomer.fullName }} and {{ getCustomer.advisor.fullName }} to
          undertake the following actions to achieve employment.
        </p>
        <table style="width: 100%; margin: 1rem 0">
          <thead>
            <tr>
              <td style="border: 1px solid black; background-color: lightgrey">
                <b style="background-color: lightgrey">Participant Details</b>
              </td>
              <td style="border: 1px solid black; background-color: lightgrey">
                <b style="background-color: lightgrey"
                  >Personal Advisor Details</b
                >
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr>
              <td style="border: 1px solid black">
                Participant Name: {{ getCustomer.fullName }}
              </td>
              <td style="border: 1px solid black">
                Personal Advisor Name: {{ getCustomer.advisor.fullName }}
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black">
                Participant Reference: {{ getCustomer.memorableId }}
              </td>
              <td style="border: 1px solid black">
                Personal Advisor Office: {{ this.selectedTeam }}
              </td>
            </tr>
            <tr>
              <td v-if="getCurrentAuditToSign.reason == 'Exit Plan' && customerProgram == 'FSS'" style="border: 1px solid black">
                Participant NINO: {{ this.customerNino }}
              </td>
              <td v-else style="border: 1px solid black">
              </td>
              <td style="border: 1px solid black">
                Personal Advisor Phone: {{ cscPhone }}
              </td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%; padding: 1rem 0 0.6rem 0">
          <thead style="width: 100%">
            <tr style="width: 100%">
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 100%;
                "
              >
                <b style="background-color: lightgrey">Signed Agreement</b>
              </td>
            </tr>
          </thead>
        </table>
        <div style="font-size: 0.8rem">
          By signing below, both parties confirm the full action plan has been
          reviewed and agreed.
        </div>
        <table style="width: 100%; margin: 1rem 0">
          <tbody style="font-size: 0.8rem">
            <tr>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              >
                Customer Signature
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              >
                {{
                  this.getCurrentAuditToSign.modeOfIntervention == "Virtual"
                    ? "Customer is unable to sign as this is a virtual intervention."
                    : ""
                }}
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              >
                Date:
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              >
                Advisor Signature
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              ></td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  padding-bottom: 5rem;
                "
              >
                Date:
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 1rem"><b>Upcoming Appointments</b></div>
        <table style="width: 100%; margin-bottom: 1rem">
          <thead>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  width: 33%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Date & Time</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Subject</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Location</b>
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr v-if="filteredMeetings.length === 0">
              <td style="border: 1px solid black" colspan="3">
                No upcoming meetings found.
              </td>
            </tr>
            <tr
              v-else
              v-for="(meeting, index) in getUpcomingMeetings"
              :key="index"
            >
              <td style="border: 1px solid black">
                {{ formattedDate(new Date(meeting.scheduledStart)) }}
              </td>
              <td style="border: 1px solid black">
                {{ meeting.subject }}
              </td>
              <td style="border: 1px solid black">
                {{ meeting.location?.address.city }}<br />
                {{ meeting.location?.address.line1 }}<br />
                {{ meeting.location?.address.line2 }}<br />
                {{ meeting.location?.address.postalCode }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 1rem"><b>Formal Review</b></div>
        <table style="width: 100%; margin-bottom: 1rem">
          <thead>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  width: 40%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Subject</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 60%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Progress Notes</b>
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr>
              <td style="border: 1px solid black">
                {{ formatDate(new Date(getCurrentAuditToSign.created)) }} -
                {{ getCurrentAuditToSign.reason }}
              </td>
              <td
                style="border: 1px solid black"
                v-if="
                  getCurrentAuditToSign.contentType != 'text/rtf' &&
                  getCurrentAuditToSign.contentType != 'text/markdown'
                "
              >
                {{ getCurrentAuditToSign.description }}
              </td>
              <td
                style="border: 1px solid black"
                v-html="getCurrentAuditToSign.description"
                v-if="
                  getCurrentAuditToSign.contentType == 'text/rtf' ||
                  getCurrentAuditToSign.contentType == 'text/markdown'
                "
              ></td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 1rem"><b>Job Goals</b></div>
        <table style="width: 100%; margin-bottom: 1rem">
          <thead>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Job Goal 1</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Job Goal 2</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  width: 33.333%;
                  background-color: lightgrey;
                "
              >
                <b style="background-color: lightgrey">Job Goal 3</b>
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr>
              <td style="border: 1px solid black">
                {{
                  getCustomer.sectorInterests.length >= 1
                    ? getCustomer.sectorInterests[0]
                    : ""
                }}
              </td>
              <td style="border: 1px solid black">
                {{
                  getCustomer.sectorInterests.length >= 2
                    ? getCustomer.sectorInterests[1]
                    : ""
                }}
              </td>
              <td style="border: 1px solid black">
                {{
                  getCustomer.sectorInterests.length >= 3
                    ? getCustomer.sectorInterests[2]
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin: 1.2rem 0 0.5rem 0"><b>Action Plan Items</b></div>
        <div style="margin-top: 0"><b>Open Actions</b></div>
        <table style="width: 100%; margin-bottom: 1rem">
          <thead>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 25%;
                "
              >
                <b style="background-color: lightgrey">My Open Action(s)</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 40%;
                "
              >
                <b style="background-color: lightgrey"
                  >What I will get from doing this</b
                >
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 10%;
                "
              >
                <b style="background-color: lightgrey">Created on</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 10%;
                "
              >
                <b style="background-color: lightgrey">Due</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 25%;
                "
              >
                <b style="background-color: lightgrey">Progress Update</b>
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr
              v-for="action in getCurrentAuditPointActions.current"
              :key="action.id"
            >
              <td style="border: 1px solid black">
                {{ action.subject }}
              </td>
              <td style="border: 1px solid black">
                {{ action.motivation }}
              </td>
              <td style="border: 1px solid black">
                {{ action.state.stateCode }}
                {{ formatDate(new Date(action.audit.createdOn)) }}
              </td>
              <td style="border: 1px solid black">
                {{ action.state.stateCode }}
                {{ formatDate(new Date(action.timeline.scheduledEnd)) }}
              </td>
              <td style="border: 1px solid black; word-break: break-all">
                {{
                  action.memos.length > 0
                    ? formatDate(
                        new Date(
                          action.memos[action.memos.length - 1].createdOn
                        )
                      ) +
                      " - " +
                      action.memos[action.memos.length - 1].body
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 0"><b>Completed Actions</b></div>
        <table style="width: 100%; margin-bottom: 1rem">
          <thead>
            <tr>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 25%;
                "
              >
                <b style="background-color: lightgrey"
                  >My Completed Action(s)</b
                >
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 40%;
                "
              >
                <b style="background-color: lightgrey"
                  >What I will get from doing this</b
                >
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 10%;
                "
              >
                <b style="background-color: lightgrey">Closed Date</b>
              </td>
              <td
                style="
                  border: 1px solid black;
                  background-color: lightgrey;
                  width: 25%;
                "
              >
                <b style="background-color: lightgrey">Progress Update</b>
              </td>
            </tr>
          </thead>
          <tbody style="font-size: 0.8rem">
            <tr
              v-for="action in getCurrentAuditPointActions.completed"
              :key="action.id"
            >
              <td style="border: 1px solid black">
                {{ action.subject }}
              </td>
              <td style="border: 1px solid black">
                {{ action.motivation }}
              </td>
              <td style="border: 1px solid black">
                {{ action.state.stateCode }}
                {{
                  action.timeline.actualEnd != null
                    ? formatDate(new Date(action.timeline.actualEnd))
                    : "N/A"
                }}
              </td>
              <td style="border: 1px solid black; word-break: break-all">
                {{
                  action.memos.length > 0
                    ? formatDate(
                        new Date(
                          action.memos[action.memos.length - 1].createdOn
                        )
                      ) +
                      " - " +
                      action.memos[action.memos.length - 1].body
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="getCurrentAuditToSign.reason == 'Exit Plan' && exitplanFlag">
          <div
            style="
              border: 1px solid black;
              background-color: lightgrey;
              width: 100%;
              margin-top: 2%;
            "
          >
            <b>Completer Summary Questionnaire</b>
          </div>
          <div id="survey-form" style="margin: 20px auto; padding: 20px">
            <table style="width: 100%; border-collapse: collapse">
              <tbody>
                <tr>
                  <td style="border: 1px solid #ccc; padding: 8px; width: 35%">
                    <b>1- Does the Participant have an up-to-date CV?</b>
                  </td>
                  <td style="border: 1px solid #ccc; padding: 8px; width: 65%">
                    <div
                      style="
                        width: 100%;
                        padding: 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                      "
                    >
                      {{ latestExitPlanSummaryData.cvAnswer || "Select" }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <b
                      >2- What work sector(s) has the Participant shown an
                      interest in and/or is applying for?</b
                    >
                  </td>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <div
                      style="
                        width: 100%;
                        padding: 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                      "
                    >
                      {{ getCustomer.sectorInterests.slice(0, 3).join(", ") }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <b
                      >3- What are the top three priority actions you recommend
                      for the Participant to take forward?</b
                    >
                  </td>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <div>
                      {{
                        `${latestExitPlanSummaryData.priority1}, ${latestExitPlanSummaryData.priority2}, ${latestExitPlanSummaryData.priority3}`
                      }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <b
                      >4- What are the Participant's current barriers to
                      Employment?</b
                    >
                  </td>
                  <td style="border: 1px solid #ccc; padding: 8px">
                    <div
                      style="
                        width: 100%;
                        padding: 8px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                      "
                    >
                      {{ latestExitPlanSummaryData.customerBarriers }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="border: 1px solid #ccc; border-top: none; padding: 8px">
              <b>5- Work Readiness</b>
              <p style="margin-bottom: 5px">
                Please tick the box below that mostly indicates this
                Participant's work readiness status as they come to the end of
                their time on the Restart Scheme.
              </p>
            </div>
            <table class="my-3" style="width: 100%; border-collapse: collapse">
              <thead>
                <tr>
                  <th style="border: 1px solid #ccc; padding: 8px"></th>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Ready for work, fully confident
                  </th>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Ready for work, confidence could grow
                  </th>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Ready for work with support
                  </th>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Significant health issues to resolve
                  </th>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Further barriers to address
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="border: 1px solid #ccc; padding: 8px">
                    Provider Response
                  </th>
                  <td
                    v-for="col in 5"
                    :key="col"
                    style="border: 1px solid #ccc; padding: 8px"
                  >
                    <input
                      type="radio"
                      name="workReadiness"
                      :value="col"
                      v-model="latestExitPlanSummaryData.workReadiness"
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style="border: 1px solid #ccc; padding: 8px">
              <b>Additional Information to explain assessment</b>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  padding: 8px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                "
              >
                {{ latestExitPlanSummaryData.additionalInfo }}
              </div>
            </div>
            <div
              class="mt-4"
              style="
                border: 1px solid #ccc;
                padding: 8px;
                font-size: 1.1rem;
                display: flex;
                justify-content: center;
              "
            >
              <b
                >This document will be requested by Jobcentre Plus and must be
                retained after leaving the Restarts Scheme</b
              >
            </div>
          </div>
        </div>
      </div>
      <div id="insert-survey"></div>
    </div>
    <div hidden>
      <button
        id="export-pdf-btn"
        class="btn btn-primary"
        @click="printDocument()"
      >
        Export To PDF
      </button>
    </div>
  </div>
</template>
<script>
import "survey-core/modern.min.css";
import signableContractCreator from "./SignableContractCreator.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
// eslint-disable-next-line no-unused-vars
import { GoToHomePage } from "@/router/index";
import signableContractCreatorVirtual from "@/components/SignableContractCreatorVirtual.vue";
import { contractFilter } from '@/composables/contractFilter';

let completeBtn;
let result;
export default {
  name: "survey-vue",
  // setup() {
  //     const azureUpload = inject('$azureUpload');

  //     // expose to template and other options API hooks
  //     return {
  //         azureUpload
  //     }
  // },
  components: {
    signableContractCreator,
    signableContractCreatorVirtual,
    // EmployabilitySurveyCreator,
    // SkillsSurveyCreator,
    // DigitalSkillsSurveyCreator,
    // LifeSkillsSurveyCreator
  },
  props: {
    // surveyType:{
    //     type: String,
    //     required: true
    // }
  },
  data() {
    return {
      ea: null,
      surveyCheckerSet: false,
      cscPhone: process.env.VUE_APP_MANDATION_PROVIDER_TELEPHONE,
      selectedTeam: "",
      // completerData: {
      //     cvAnswer: '',
      //     priority1: '',
      //     priority2: '',
      //     priority3: '',
      //     customerBarriers: '',
      //     workReadiness: null,
      //     additionalInfo: ''
      // },
    };
  },
  computed: {
    ...mapState(useCommonStore, [
      "getActionPlan",
      "getCustomer",
      "getActions",
      "getCurrentAuditPointActions",
      "getCurrentAuditToSign",
      "getLoggedInAdvisorsTeams",
      "getUpcomingMeetings",
      "getRecommendedActionTemplates",
      "getAuditPoints",
      "getPRaPOrder"
    ]),

    ...mapState(useAdvisorDashboardStore, ["getCompleterSummaryData"]),

    customerProgram() {
      const { customerProgram } = contractFilter();
      return customerProgram?.value || null; // Return the value or null if undefined
    },

    currentPrap() {
      return this.getPRaPOrder?.documents?.slice(-1)[0] || null;
    },

    customerNino() {
      const currentPRaP = this.currentPrap;
      return currentPRaP ? currentPRaP.extraInfoLine.ninoReferenceNo : "N/A";
    },

    latestExitPlanSummaryData() {
      // Filter for audit points with the reason "Exit Plan"
      const exitPlanPoints = this.getAuditPoints.filter(
        (point) => point.reason === "Exit Plan"
      );

      if (exitPlanPoints.length > 0) {
        // If there are any "Exit Plan" points, return the completerSummaryData from the first one (latest)
        return exitPlanPoints[0].completerSummaryData;
      } else {
        // If no "Exit Plan" points are found, return null or a default value
        return null;
      }
    },

    exitplanFlag() {
      const recommendedActions = this.getRecommendedActionTemplates;

      if (!recommendedActions || recommendedActions.length === 0) {
        console.warn("No recommended action templates found.");
        return false;
      }

      return recommendedActions.some(
        (action) =>
          action.catalogName === "restart-v2" ||
          action.catalogName === "restart-v1"
      );
    },

    getIsAuditVirtual() {
      var check = this.getCurrentAuditToSign == "Virtual" ? true : false;
      return check;
    },

    filteredMeetings() {
      const fourWeeksFromNow = new Date();
      fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
      // Filter meetings that are scheduled within the next 4 weeks
      return this.getUpcomingMeetings.filter((meeting) => {
        const meetingDate = new Date(meeting.scheduledStart);
        return meetingDate <= fourWeeksFromNow;
      });
    },
  },
  methods: {
    ...mapActions(useCommonStore, [
      "SetCustomerId",
      "GetActionPlan",
      "GetCustomer",
      "GetAuditActions",
      "UploadContract",
      "GetAuditByVersion",
      "CreateAuditPoint",
      "GetLoggedInAdvisorsTeams",
      "getUpcomingMeetings",
      "fireErrorAlert",
    ]),
    ...mapActions(useAdvisorDashboardStore, [
      "generateContract",
      "getPDFMarkup",
      "setCompleterSummaryData",
    ]),
    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },

    formattedDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const hours = date.getHours();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format

      const formattedDate = `${
        date.getDate() < 10 ? "0" : ""
      }${date.getDate()}/${date.getMonth() < 9 ? "0" : ""}${
        date.getMonth() + 1
      }/${date.getFullYear()}
        ${daysOfWeek[date.getDay()]} ${formattedHours}:${
        date.getMinutes() < 10 ? "0" : ""
      }${date.getMinutes()} ${amOrPm}`;

      return formattedDate;
    },

    // eslint-disable-next-line no-unused-vars
    async printDocument(customerSignature, advisorSignature) {
      if (this.selectedTeam == "") {
        if (this.getCustomer.owningTeam == null) {
          this.fireErrorAlert(
            "No advisor office location present. Contract can't be saved.",
            "printDocument"
          );
        }
        this.selectedTeam = this.getCustomer.owningTeam.name;
      }
      const nino = this.customerProgram === 'FSS' ? 'Participant NI Number: ' + this.customerNino : null;

      var generatedContract = await this.generateContract(
        customerSignature,
        advisorSignature,
        this.getCurrentAuditPointActions,
        this.getCustomer,
        this.getCurrentAuditToSign,
        this.selectedTeam,
        this.latestExitPlanSummaryData,
        nino
      );

      generatedContract.getBuffer((buffer) => {
        this.UploadContract(this.$route.params.version, buffer);
        GoToHomePage();
      });
    },

    tryGetResults() {
      var header = document.getElementsByClassName(
        "svd-test-results__header-types"
      )[0];
      var count = 0;
      while (header == undefined && count < 20) {
        header = document.getElementsByClassName(
          "svd-test-results__header-types"
        )[0];
        count++;
      }
      for (var i = 0; i < header.children.length; i++) {
        if (header.children[i].innerText == "As JSON") {
          header.children[i].addEventListener("click", () => {
            var count2 = 0;
            var resultsDiv = document.getElementsByClassName(
              "svd-test-results__text"
            )[0];
            while (resultsDiv == undefined && count2 < 20) {
              resultsDiv = document.getElementsByClassName(
                "svd-test-results__text"
              )[0];
              count2++;
            }
            if (resultsDiv != undefined) {
              result = resultsDiv.children[0].innerHTML;
            }
            var resultObj = JSON.parse(result);
            this.printDocument(
              resultObj.declarationSignatureCustomer,
              resultObj.declarationSignatureAdvisor
            );
            // if(this.$route.params.type == "ea"){
            //     location.href = `${location.origin}/AdvisorDashboard/${this.$route.params.id}`
            // }
            // else if(this.$route.params.type == "customer"){
            //     location.href = `${location.origin}/CustomerDashboard/${this.$route.params.id}`
            // }
            document.getElementsByClassName(
              "svd-simulator-main"
            )[0].hidden = true;
            setTimeout(() => {}, 100);
          });
          header.children[i].click();
          document.getElementsByClassName(
            "svd-simulator-main"
          )[0].hidden = true;
          document.getElementsByClassName(
            "svc-preview__test-again"
          )[0].hidden = true;
          document.getElementsByTagName("survey-results")[0].hidden = true;
        }
      }
    },
  },
  watch: {
    getCurrentAuditToSign() {
      if (this.getCurrentAuditToSign != {} && !this.surveyCheckerSet) {
        this.surveyCheckerSet = true;
        setTimeout(() => {
          var content = document.getElementsByClassName(
            "svc-tabbed-menu-item__text"
          );
          for (var i = 0; i < content.length; i++) {
            if (content[i].innerHTML == "Preview") {
              content[i].parentNode.click();
            }
          }
          setTimeout(() => {
            var survey = document.getElementsByClassName(
              "svc-plugin-tab__content"
            )[0];
            document.getElementById("insert-survey").appendChild(survey);
            var container = document.getElementById("insert-survey");
            container.addEventListener("mouseover", () => {
              if (completeBtn == undefined) {
                var checkForButton = document.getElementsByClassName(
                  "sd-navigation__complete-btn"
                )[0];
                if (checkForButton != undefined) {
                  completeBtn = checkForButton;
                  completeBtn.addEventListener("click", this.tryGetResults);
                }
              }
            });
            // this.setSurvey(survey);
            // var modalContent = document.getElementById("modal-content");
            // // modalContent.appendChild(survey);
            // modalContent.addEventListener("mouseover", ()=>{
            //     if(completeBtn == undefined){
            //         var checkForButton = document.getElementsByClassName("sd-navigation__complete-btn")[0];
            //         if(checkForButton!=undefined){
            //             completeBtn = checkForButton;
            //             completeBtn.addEventListener("click", this.tryGetResults);
            //         }
            //     }
            // });
          }, 200);
        }, 1000);
      }
    },
  },
  async mounted() {
    this.SetCustomerId(this.$route.params.id);
    this.GetAuditActions(this.$route.params.version).then(() => {
      this.getCurrentAuditPointActions.current.sort(
        (a, b) =>
          new Date(b.timeline.scheduledEnd) - new Date(a.timeline.scheduledEnd)
      );
    });
    this.GetAuditByVersion(this.$route.params.version);
    //this.GetLoggedInAdvisorsTeams();
    await this.GetCustomer();
    this.selectedTeam = this.getCustomer.owningTeam.name;
    if (this.getCurrentAuditToSign != {} && !this.surveyCheckerSet) {
      this.surveyCheckerSet = true;
      setTimeout(() => {
        var content = document.getElementsByClassName(
          "svc-tabbed-menu-item__text"
        );
        for (var i = 0; i < content.length; i++) {
          if (content[i].innerHTML == "Preview") {
            content[i].parentNode.click();
          }
        }
        setTimeout(() => {
          var survey = document.getElementsByClassName(
            "svc-plugin-tab__content"
          )[0];
          document.getElementById("insert-survey").appendChild(survey);
          var container = document.getElementById("insert-survey");
          container.addEventListener("mouseover", () => {
            if (completeBtn == undefined) {
              var checkForButton = document.getElementsByClassName(
                "sd-navigation__complete-btn"
              )[0];
              if (checkForButton != undefined) {
                completeBtn = checkForButton;
                completeBtn.addEventListener("click", this.tryGetResults);
              }
            }
          });
          // this.setSurvey(survey);
          // var modalContent = document.getElementById("modal-content");
          // // modalContent.appendChild(survey);
          // modalContent.addEventListener("mouseover", ()=>{
          //     if(completeBtn == undefined){
          //         var checkForButton = document.getElementsByClassName("sd-navigation__complete-btn")[0];
          //         if(checkForButton!=undefined){
          //             completeBtn = checkForButton;
          //             completeBtn.addEventListener("click", this.tryGetResults);
          //         }
          //     }
          // });
        }, 200);
      }, 1000);
    }
  },
  beforeUnmount() {
    completeBtn = undefined;
  },
};
</script>
