import { defineStore } from "pinia";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";
import { RTFJS } from "rtf.js";

import { generate } from '../services/ActionPlanContractGenerator'



export const useAdvisorDashboardStore = defineStore({
    id:'advisorDashboard',
    state: () =>({
        targetedActivities:[],
        showModal: false,
        modalContent: null,
        modalContentData: null,
        modalTitle: "",
        contextMenuActionId: "",
        survey: null,
        contractView: null,
        meetingEventView: null,
        createActionView: null,
        createAppointmentView: null,
        appointmentInfo: null,
        updateActionView: null,
        customerProfileView: null,
        updateStartView: null,
        auditDescView: null,
        editAuditDescView: null,
        convertActivityView: null,
        memoView: null,
        actionIdToCancel: null,
        cancellationReasonView: null,
        currentActionToUpdate: null,
        currentTaskToUpdate: null,
        currentActionToAddTask: null,
        isActionUpdating: null,
        updateAssessmentItemDueView:null,
        forceCreateAuditView:null,
        completerSummaryData:null,
    }),
    getters: {
        getTargetedActivities: (state) => state.targetedActivities,
        getShowModal: (state) => state.showModal,
        getModalContent: (state) => state.modalContent,
        getModalContentData: (state) => state.modalContentData,
        getModalTitle: (state) => state.modalTitle,
        getContextMenuActionId: (state) => state.contextMenuActionId,
        getSurvey: (state) => state.survey,
        getContractView: (state) => state.contractView,
        getMeetingEventView: (state) => state.meetingEventView,
        getCreateActionView: (state) => state.createActionView,
        getCreateAppointmentView: (state) => state.createAppointmentView,
        getUpdateActionView: (state) => state.updateActionView,
        getAppointmentInfoView: (state) => state.appointmentInfo,
        getCustomerProfileView: (state) => state.customerProfileView,
        getUpdateStartView: (state) => state.updateStartView,
        getAuditDescView: (state) => state.auditDescView,
        getEditAuditDescView: (state) => state.editAuditDescView,
        getConvertActivityView: (state) => state.convertActivityView,
        getMemoView: (state) => state.memoView,
        getActionIdToCancel: (state) => state.actionIdToCancel,
        getCancellationReasonView: (state) => state.cancellationReasonView,
        getCurrentActionToUpdate: (state) => state.currentActionToUpdate,
        getCurrentTaskToUpdate: (state) => state.currentTaskToUpdate,
        getCurrentActionToAddTask: (state) => state.currentActionToAddTask,
        getIsActionUpdating: (state) => state.isActionUpdating,
        getUpdateAssessmentItemDueView: (state) => state.updateAssessmentItemDueView, 
        getForceCreateAuditView: (state) => state.forceCreateAuditView, 
        getCompleterSummaryData: (state) => state.completerSummaryData
    },
    actions: {
        resetCachedModalViews() {
            this.contractView = null
            this.meetingEventView = null
            this.createActionView = null
            this.createAppointmentView = null
            this.updateActionView = null
            this.customerProfileView = null
            this.updateStartView = null
            this.auditDescView = null
            this.convertActivityView = null
            this.memoView = null
            this.cancellationReasonView = null
        },
        formatDate(date){
            return [
                date.getDate() < 10 ? '0' + date.getDate(): date.getDate(),
                (date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1),
                date.getFullYear() 
                ].join('/');
        },
        getPDFMarkup(html){
            var markup = htmlToPdfmake(html.outerHTML);
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            return pdfMake.createPdf({ content:markup});
        },
        async ConvertFromRtfToHtml(rtf){
            const stringToArrayBuffer = (string) => {
                const buffer = new ArrayBuffer(string.length);
                const bufferView = new Uint8Array(buffer);
                for (let i = 0; i < string.length; i++) {
                bufferView[i] = string.charCodeAt(i);
                }
                return buffer;
            };
            const doc = new RTFJS.Document(stringToArrayBuffer(rtf));
            const htmlElements = await doc.render();
            var html = htmlElements[0];
            //html.style.fontFamily = "Courier";
            for(var i = 1; i < htmlElements.length; i++){
                //htmlElements[i].style.fontFamily = "Courier";
                html.appendChild(htmlElements[i]);
            }
            return html.outerHTML;
        },
        async generateContract(customerSignature, advisorSignature, auditActions, customer, currentAudit, selectedTeam, completerSummaryData, nino){
            return await generate(customerSignature, advisorSignature, auditActions, customer, currentAudit, selectedTeam, completerSummaryData, nino)
        },
        setCompleterSummaryData(content){
            this.completerSummaryData = content;
        },
        setIsActionUpdating(content){
            this.isActionUpdating = content;
        },
        setCurrentActionToAddTask(content){
            this.currentActionToAddTask = content;
        },
        setMemoView(content){
            this.memoView = content;
        },
        setCancellationReasonView(content){
            this.cancellationReasonView = content;
        },
        setActionIdToCancel(id){
            this.actionIdToCancel = id;
        },
        setAuditDescView(content){
            this.auditDescView = content;
        },
        setEditAuditDescView(content){
            this.editAuditDescView = content;
        },
        setUpdateStartView(content){
            this.updateStartView = content;
        },
        setCustomerProfileView(content){
            this.customerProfileView = content;
        },
        setCurrentTaskToUpdate(task){
            this.currentTaskToUpdate = task;
        },
        setCurrentActionToUpdate(action){
            this.currentActionToUpdate = action;
        },
        setUpdateActionView(content){
            this.updateActionView = content;
        },
        setConvertActivityView(content){
            this.convertActivityView = content;
        },
        setCreateActionView(content){
            this.createActionView = content;
        },
        setCreateAppointmentView(content){
            this.createAppointmentView = content;
        },
        setCreateAppointmentInfoView(content){
            this.appointmentInfo = content;
        },
        setMeetingEventView(content){
            this.meetingEventView = content;
        },
        setContractView(content){
            this.contractView = content;
        },
        setContextMenuActionId(content){
            this.contextMenuActionId = content;
        },
        setModalContent(content){
            this.modalContent = content;
        },
        setModalContentData(content){
            this.modalContentData = content;
        },
        setModalTitle(content){
            this.modalTitle = content;
        },
        toggleModal(){
            this.showModal = !this.showModal;
        },
        setSurvey(survey){
            this.survey = survey;
        },
        setUpdateAssessmentItemDueView(content){
            this.updateAssessmentItemDueView = content;
        },
        setForceCreateAuditView(content){
            this.forceCreateAuditView = content;
        }
    },
})